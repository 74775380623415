import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Divider,
  Box
} from '@mui/material';
import SearchInput from 'components/ai-product/ai_product_search';
import PaginationFormControl from 'components/pagination';
import { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { ai_productService } from 'services/ai_product.service';
import { rmfActionService } from 'services/rmf-action.service';

const ComplianceActions = ({ compliance }) => {
  const [selectedNode, rmfData, handleDepartmentSelect, orgListArray] = useOutletContext();
  const [aiProducts, setAiProducts] = useState([]);
  const [rmfActions, setRmfActions] = useState([]);
  const [filteredProductsWithActions, setFilteredProductsWithActions] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(12);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState(''); // Added search query state

  // Calculate pagination
  const indexOfLastProduct = currentPage * itemsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;
  const currentProducts = filteredProductsWithActions.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );
  const pageCount = Math.ceil(filteredProductsWithActions.length / itemsPerPage);

  const getOrgNameById = (orgId) => {
    const org = orgListArray.find((org) => org.id === orgId);
    return org ? org.name : 'Unknown';
  };

  useEffect(() => {
    loadAiProducts();
  }, [orgListArray]);

  const loadAiProducts = async () => {
    const allAiProducts = await ai_productService.query();

    // Filter AI products to only include those whose org_id is in orgListArray
    const filteredAiProducts = allAiProducts.filter((product) =>
      orgListArray.some((org) => org.id === product.org_id)
    );
    console.log('filteredAiProducts', filteredAiProducts);
    setAiProducts(filteredAiProducts);
  };

  useEffect(() => {
    const fetchRmfActions = async () => {
      try {
        const allRmfActions = await rmfActionService.queryAllRmfActions();
        setRmfActions(allRmfActions);
        console.log('allRmfActions', allRmfActions);
      } catch (err) {
        console.error('Error loading RMF Actions', err);
      }
    };

    fetchRmfActions();
  }, []);

  useEffect(() => {
    if (aiProducts.length > 0 && rmfActions.length > 0) {
      // Filter and map AI products with their matching RMF actions
      const productsWithActions = aiProducts
        .map((product) => ({
          ...product,
          actions: rmfActions.filter((action) =>
            product.rmfActions?.some((rmfAction) => rmfAction.action_id === action._id)
          )
        }))
        // Only include products with at least one action
        .filter((product) => product.actions.length > 0);

      setFilteredProductsWithActions(productsWithActions);
      setCurrentPage(1);
    }
  }, [aiProducts, rmfActions]);

  const filteredResults = filteredProductsWithActions
    .map((product) => {
      const filteredActions = product.actions.filter((action) =>
        action.action.toLowerCase().includes(searchQuery.toLowerCase())
      );

      return {
        ...product,
        actions: filteredActions // Only include matching actions
      };
    })
    .filter((product) => product.actions.length > 0); // Only return products with matching actions

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(event.target.value);
    setCurrentPage(1); // Reset to first page when items per page change
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <div>
      <Typography variant="h4" sx={{ color: 'gray', mb: 2 }}>
        Action
      </Typography>
      <Box display="flex" justifyContent="space-between" alignItems="flex-start" sx={{ gap: 3 }}>
        <SearchInput searchQuery={searchQuery} setSearchQuery={setSearchQuery} label="Search" />

        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <PaginationFormControl
            itemsPerPage={itemsPerPage}
            handleItemsPerPageChange={handleItemsPerPageChange}
            pageCount={pageCount}
            currentPage={currentPage}
            handleChangePage={handleChangePage}
          />
        </Box>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Org Name</TableCell>
              <TableCell>Product Name</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredResults.map((product) => (
              <TableRow key={product._id}>
                <TableCell>{getOrgNameById(product.org_id)}</TableCell>
                <TableCell>{product.product_name}</TableCell>
                <TableCell>
                  {product.actions.map((action, index) => (
                    <div key={action._id}>
                      {action.action}
                      {index < product.actions.length - 1 && <Divider sx={{ margin: '5px 0' }} />}
                    </div>
                  ))}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ComplianceActions;

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import { cloneDeep, findIndex } from 'lodash';
import { useEffect, useState } from 'react';
import { traceboxService } from 'services/tracebox-products.service';
import RmfActionTypes from './rmf-action/rmf-action-types';

const defaultProduct = () => {
  return {
    _id: null,
    product_name: '',
    description: '',
    org_id: null,
    type: 'Internal',
    rmfActionCategory: '', // TODO delete this
    rmfActions: []
  };
};

const AIProductCreateEdit = ({ open, handleClose, saveProduct, product, aiProductOrgId }) => {
  const [traceboxProducts, setTraceboxProducts] = useState([]);
  const [prevProductId, setPrevProductId] = useState(null);
  const [newProduct, setNewProduct] = useState({
    ...defaultProduct(),
    org_id: aiProductOrgId
  });

  useEffect(() => {
    getTraceboxProducts();
  }, []);

  if (product?._id !== prevProductId) {
    setPrevProductId(product?._id);

    const tempProduct = {
      ...defaultProduct(),
      org_id: aiProductOrgId
    };
    setNewProduct(product ? { ...tempProduct, ...product } : tempProduct);
  }

  const getTraceboxProducts = async () => {
    const traceboxProducts = await traceboxService.queryAlltraceboxProducts();
    setTraceboxProducts(traceboxProducts);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewProduct((prevProduct) => ({
      ...prevProduct,
      [name]: value
    }));
  };

  const handleRmfActionsChange = ({ id, action, type, ai_actors, checked }) => {
    console.log('handleRmfActionsChange', id, checked);

    const rmfActions = cloneDeep(newProduct.rmfActions);
    const index = findIndex(rmfActions, { action_id: id });
    if (checked && index < 0) {
      rmfActions.push({ action_id: id, action, type, ai_actors, timestamp: new Date().getTime() });
    } else if (!checked && index > -1) {
      rmfActions.splice(index, 1);
    }

    console.log('new rmfActions!', rmfActions);
    setNewProduct((prevProduct) => ({
      ...prevProduct,
      rmfActions: rmfActions
    }));
  };

  const onSaveEditAction = ({ action_id, users, description }) => {
    const rmfActions = cloneDeep(newProduct.rmfActions);
    const index = rmfActions.findIndex((o) => o.action_id === action_id);
    if (index > -1) {
      rmfActions[index].users = users;
      rmfActions[index].description = description;

      setNewProduct((prevProduct) => ({
        ...prevProduct,
        rmfActions
      }));
    } else {
      console.warning(`${action_id} not found in ai_product`);
    }
  };

  const handleSubmit = () => {
    saveProduct(newProduct);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={false}
      fullWidth
      fullScreen
      sx={{
        '& .MuiDialog-paper': {
          // height: '85vh',
          padding: '.7rem'
        }
      }}
    >
      <DialogTitle>
        {product
          ? `Edit AI Product - ${product.product_name}- ${product._id}`
          : `Add New AI Product`}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth margin="dense" variant="outlined">
              <InputLabel>Type</InputLabel>
              <Select name="type" value={newProduct.type} onChange={handleChange} label="Type">
                <MenuItem value="Internal">Internal</MenuItem>
                <MenuItem value="Third party">Third party</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            {newProduct.type === 'Internal' ? (
              <FormControl fullWidth margin="dense" variant="outlined">
                <InputLabel>Product Name</InputLabel>
                <Select
                  name="product_name"
                  value={newProduct.product_name}
                  onChange={handleChange}
                  label="Product Name"
                >
                  <MenuItem value="">None</MenuItem>
                  {traceboxProducts.map((product) => (
                    <MenuItem key={product.id} value={product.id}>
                      {product.id}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <TextField
                autoFocus
                margin="dense"
                name="product_name"
                label="Product Name"
                type="text"
                fullWidth
                variant="outlined"
                value={newProduct.product_name}
                onChange={handleChange}
              />
            )}
          </Grid>
        </Grid>
        <TextField
          margin="dense"
          name="description"
          label="Description"
          type="text"
          fullWidth
          variant="outlined"
          multiline
          rows={2}
          value={newProduct.description}
          onChange={handleChange}
        />

        <RmfActionTypes
          newProduct={newProduct}
          onSelectedActionChange={handleRmfActionsChange}
          onSaveEditAction={onSaveEditAction}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSubmit}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AIProductCreateEdit;

import { Box } from '@mui/material';
import Header from 'components/header/header';
import { createContext, useContext, useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { httpService } from 'services/http.service';
import './App.css';

import { GoogleOAuthProvider } from '@react-oauth/google';

import ComplianceIndex from 'pages/compliance-index';
import Home from 'pages/home';
import NotFoundIndex from 'pages/not-found-index';
import OrgIndex from 'pages/org-index';
import SignIn from 'pages/sign-in'; // Import additional pages as needed
import ComplianceRMF from 'components/compliance/rmf/compliance-rmf';
import ComplianceAct from 'components/compliance/compliance-act';
import ComplianceAIProduct from 'components/compliance/compliance-ai-product';
import ComplianceUsers from 'components/compliance/users/compliance-users';
import ComplianceActions from 'components/compliance/actions/compliance-actions';

const UserContext = createContext(null);
export const useUserContext = () => {
  return useContext(UserContext);
};

function App() {
  const location = useLocation();
  const navigate = useNavigate();

  const [isSignedIn, setIsSignedIn] = useState(!!window.sessionStorage.getItem('user'));
  const [user, setUser] = useState(null);

  const signOut = async () => {
    console.log('signOut!!!');
    window.sessionStorage.clear();

    if (isSignedIn) {
      setIsSignedIn(false);
      let result = await httpService.get('auth/signout');
      console.log(result);
    }
    if (location.pathname !== '/signin') {
      navigate('/signin');
    }
  };

  useEffect(() => {
    let currUser = null;
    try {
      currUser = JSON.parse(window.sessionStorage.getItem('user'));
    } catch (err) { }
    if (location.pathname === '/signin' || !currUser || !currUser.email) {
      signOut();
    } else {
      setIsSignedIn(true);
      setUser(currUser);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <Box className="App" sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        <UserContext.Provider
          value={{
            user,
            setUser
          }}
        >
          <Header isSignedIn={isSignedIn} onSignOut={signOut} />

          <Routes>
            <Route index element={<Home />} />
            <Route path="/compliance/:_id" element={<ComplianceIndex />}>
              <Route index element={<ComplianceRMF />} />
              <Route path="nist" element={<ComplianceRMF />} />
              <Route path="products" element={<ComplianceAIProduct />} />
              <Route path="eu-ai-act" element={<ComplianceAct />} />
              <Route path="users" element={<ComplianceUsers />} />
              <Route path="actions" element={<ComplianceActions />} />
            </Route>
            <Route path="/org" element={<OrgIndex />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="*" element={<NotFoundIndex />} />
            {/* Add more routes as needed */}
          </Routes>
        </UserContext.Provider>
      </Box>
    </GoogleOAuthProvider>
  );
}

export default App;

// AiProductList.jsx
import React, { useEffect, useState } from 'react';
import { ai_productService } from '../../services/ai_product.service';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  IconButton,
  Box
} from '@mui/material';
import AIProductCreateEdit from './ai-product-create-edit';
import { DeleteIcon, EditIcon } from '../../icons';
import AiProductSearch from './ai_product_search';
import PaginationFormControl from 'components/pagination';
import RmfActionTypes from './rmf-action/rmf-action-types';
import SearchInput from './ai_product_search';

const AiProductList = ({ orgListArray, aiProductOrgId }) => {
  const [aiProducts, setAiProducts] = useState([]);
  const [aiProductsearchQuery, setAiProductSearchQuery] = useState('');
  const [open, setOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(12);

  useEffect(() => {
    loadAiProducts();
    setCurrentPage(1);
  }, [orgListArray]);

  const loadAiProducts = async () => {
    const allAiProducts = await ai_productService.query();

    // Filter AI products to only include those whose org_id is in orgListArray
    const filteredAiProducts = allAiProducts.filter((product) =>
      orgListArray.some((org) => org.id === product.org_id)
    );

    setAiProducts(filteredAiProducts);
    console.log('Filtered AI Products:', filteredAiProducts);
  };

  const getOrgNameById = (orgId) => {
    const org = orgListArray.find((org) => org.id === orgId);
    return org ? org.name : 'Unknown';
  };

  const handleOpenProductDialog = (product = null) => {
    setSelectedProduct(product);
    setOpen(true);
  };

  const handleCloseProductDialog = () => {
    setOpen(false);
    setSelectedProduct(null);
  };

  const saveProduct = async (product) => {
    await ai_productService.save(product);
    loadAiProducts();
    handleCloseProductDialog();
  };

  const deleteProduct = async (productId) => {
    await ai_productService.remove(productId);
    loadAiProducts();
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(event.target.value);
    setCurrentPage(1); // Reset to first page when items per page change
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const filteredProducts = aiProducts.filter((product) => {
    const orgName = getOrgNameById(product.org_id);
    return (
      product.product_name.toLowerCase().includes(aiProductsearchQuery.toLowerCase()) ||
      product.description.toLowerCase().includes(aiProductsearchQuery.toLowerCase()) ||
      orgName.toLowerCase().includes(aiProductsearchQuery.toLowerCase())
    );
  });

  // Calculate pagination
  const indexOfLastProduct = currentPage * itemsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);
  const pageCount = Math.ceil(filteredProducts.length / itemsPerPage);

  return (
    <div>
      <Box display="flex" justifyContent="space-between" alignItems="flex-start" sx={{ gap: 3 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpenProductDialog()}
          sx={{ height: '40px', width: '200px' }}
        >
          Add Ai Product
        </Button>
        <SearchInput
          searchQuery={aiProductsearchQuery}
          setSearchQuery={setAiProductSearchQuery}
          label="Search AI Products" // Pass a custom label
        />
        {/* <AiProductSearch
          aiProductsearchQuery={aiProductsearchQuery}
          setAiProductSearchQuery={setAiProductSearchQuery}
        /> */}

        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <PaginationFormControl
            itemsPerPage={itemsPerPage}
            handleItemsPerPageChange={handleItemsPerPageChange}
            pageCount={pageCount}
            currentPage={currentPage}
            handleChangePage={handleChangePage}
          />
        </Box>
      </Box>
      <TableContainer component={Paper} style={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Product Name</TableCell>
              <TableCell>Description</TableCell>
              {/* <TableCell>Organization ID</TableCell> */}
              <TableCell
                style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
              >
                Organization Name
              </TableCell>
              <TableCell>Type</TableCell>
              {/* <TableCell>Action Category</TableCell> */}
              <TableCell>Actions </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentProducts.map((aiProduct) => (
              <TableRow key={aiProduct._id}>
                <TableCell>{aiProduct.product_name}</TableCell>
                <TableCell>{aiProduct.description}</TableCell>
                {/* <TableCell>{aiProduct.org_id}</TableCell> */}
                <TableCell>{getOrgNameById(aiProduct.org_id)}</TableCell>
                <TableCell>{aiProduct.type}</TableCell>
                {/* <TableCell>{aiProduct.rmfActionCategory}</TableCell> */}
                <TableCell>{aiProduct.rmfActions?.length}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleOpenProductDialog(aiProduct)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => deleteProduct(aiProduct._id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <AIProductCreateEdit
        open={open}
        handleClose={handleCloseProductDialog}
        saveProduct={saveProduct}
        product={selectedProduct}
        aiProductOrgId={aiProductOrgId}
      />
    </div>
  );
};

export default AiProductList;

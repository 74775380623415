import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const OrgSelectParent = ({
  selectedId = '',
  currOrg = null,
  orgList = [],
  disabled = false,
  onSelectedChange = () => null
}) => {
  return (
    <FormControl fullWidth margin="dense">
      <InputLabel id="parent-id-label">Parent Organization</InputLabel>
      <Select
        fullWidth
        labelId="parent-id-label"
        name="parent_id"
        value={selectedId}
        onChange={(e) => onSelectedChange(e.target.value)}
        label="Parent Organization"
        disabled={disabled}
      >
        <MenuItem value="" disabled={orgList?.length > 0}>
          None
        </MenuItem>
        {orgList.map((org) => (
          <MenuItem key={org._id} value={org._id} disabled={org._id === currOrg._id}>
            {org.name} ({org._id})
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default OrgSelectParent;

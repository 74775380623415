import React from 'react';
import { Box, Button } from '@mui/material';

const Departments = ({ onDepartmentSelect }) => {
  const departmentsRmfDefaults = [
    { name: 'product', label: 'Product', val: 0.4 },
    { name: 'sales', label: 'Sales', val: 0.3 },
    { name: 'marketing', label: 'Marketing', val: 0.2 },
    { name: 'hr', label: 'HR', val: 0.1 },
    { name: 'legal', label: 'Legal & Secretary', val: 0.1 },
    { name: 'emea', label: 'EMEA', val: 0.1 },
  ];

  const handleDepartmentClick = (department) => {
    onDepartmentSelect(department.label, department.val);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        {departmentsRmfDefaults.map((department) => (
          <Button
            key={department.name}
            variant="contained"
            sx={{ m: 1 }}
            onClick={() => handleDepartmentClick(department)}
          >
            {department.label}
          </Button>
        ))}
      </Box>
    </Box>
  );
};

export default Departments;

import React, { useState, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { orgService } from '../../services/org.service';
import { DeleteIcon, EditIcon, PlusIcon } from '../../icons';
import { ButtonBase } from '@mui/material';
import ConfirmationDialog from 'components/dialog/confirmation-dialog';
import OrgCreateEdit from 'components/org/org-create-edit';

const getNodeAndChildDetails = (node) => {
  if (!node) return [];
  let nodeDetails = [{ id: node._id, name: node.name }];
  if (node.children) {
    Object.values(node.children).forEach((child) => {
      nodeDetails = nodeDetails.concat(getNodeAndChildDetails(child));
    });
  }
  return nodeDetails;
};

const TreeHierarchicalList = ({ onNodeSelect, onNodeDetails, parentOrg, orgList, setRmfData }) => {
  const [treeData, setTreeData] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [selectedNodeId, setSelectedNodeId] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState(null);

  // confirm delete dialog state
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteDialogProps, setDeleteDialogProps] = useState({
    body: '',
    onConfirm: () => null
  });

  useEffect(() => {
    loadOrgs(parentOrg);
  }, [parentOrg, orgList]);

  const handleOpen = (org = null, isNew = false) => {
    console.log('Opening dialog:', { org, isNew });
    if (isNew) {
      setSelectedOrg({
        name: '',
        rmf: {
          manage: 0,
          measure: 0,
          map: 0,
          govern: 0
        },
        org_id: org ? org.org_id : null,

        parent_id: org ? org._id : null
      });
    } else {
      setSelectedOrg(org);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedOrg(null);
  };

  const saveOrg = async (org) => {
    console.log('selectedOrg*****', selectedOrg);
    if (selectedOrg) {
      org.org_id = selectedOrg.org_id;
      org.parent_id = selectedOrg.parent_id;
    }
    console.log('saveOrg tree', org);
    await orgService.save(org);
    setRmfData(org.rmf);
    loadOrgs(parentOrg);
    handleClose();
  };

  const openDeleteDialog = (org) => {
    console.log('showDeleteDialog!!', org);
    setDeleteDialogProps({
      body: `Delete organization '${org.name}' with id '${org._id}'? This action is permanent.'`,
      onConfirm: async () => {
        console.log('deleteOrg!', org._id);
        setShowDeleteDialog(false);
        await orgService.remove(org._id);
        loadOrgs(parentOrg);
      }
    });

    setShowDeleteDialog(true);
  };

  const loadOrgs = async (parentOrg) => {
    const newTree = await orgService.getOrgTree(parentOrg);
    if (newTree) {
      setTreeData([newTree]);
      if (!expanded || !expanded.length) {
        setExpanded(getAllNodeIds(newTree));
      }
    } else {
      setTreeData([]);
    }
  };

  const getAllNodeIds = (nodes) => {
    if (!nodes) return [];
    let nodeIds = [nodes._id];
    if (nodes.children) {
      Object.values(nodes.children).forEach((child) => {
        nodeIds = nodeIds.concat(getAllNodeIds(child));
      });
    }
    return nodeIds;
  };

  const handleNodeClick = (event, node) => {
    event.stopPropagation();
    setSelectedNodeId(node._id);

    const nodeAndChildDetails = getNodeAndChildDetails(node);

    if (onNodeDetails) {
      onNodeDetails(nodeAndChildDetails);
    }

    if (onNodeSelect) {
      onNodeSelect(node);
    }
  };

  const renderTree = (nodes) => (
    <TreeItem
      key={nodes._id}
      itemId={nodes._id}
      label={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ marginRight: '10px' }}>{nodes.name}</span>
          {selectedNodeId === nodes._id && (
            <>
              <ButtonBase
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleOpen(nodes);
                }}
                style={{ cursor: 'pointer', marginRight: '5px' }}
              >
                <EditIcon />
              </ButtonBase>
              <ButtonBase
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  openDeleteDialog(nodes);
                }}
                style={{ cursor: 'pointer', marginRight: '5px' }}
              >
                <DeleteIcon />
              </ButtonBase>
              <ButtonBase
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleOpen(nodes, true);
                }}
                style={{ cursor: 'pointer' }}
              >
                <PlusIcon />
              </ButtonBase>
            </>
          )}
        </div>
      }
      onClick={(event) => handleNodeClick(event, nodes)}
    >
      {nodes.children && typeof nodes.children === 'object'
        ? Object.values(nodes.children).map((node) => renderTree(node))
        : null}
    </TreeItem>
  );

  return (
    <>
      <Stack spacing={2}>
        <Box sx={{ minHeight: 352, maxWidth: 400 }}>
          {expanded && expanded.length && (
            <SimpleTreeView defaultExpandedItems={expanded} expansionTrigger="iconContainer">
              {treeData.map((data) => renderTree(data))}
            </SimpleTreeView>
          )}
        </Box>
      </Stack>
      {open && (
        <OrgCreateEdit
          open={open}
          handleClose={handleClose}
          saveOrg={saveOrg}
          org={selectedOrg}
          hideParent={true}
        />
      )}
      {
        <ConfirmationDialog
          open={showDeleteDialog}
          title={'Confirm Delete'}
          onCancel={() => setShowDeleteDialog(false)}
          {...deleteDialogProps}
        />
      }
    </>
  );
};

export default TreeHierarchicalList;

import React from 'react';
import { Typography } from '@mui/material';

const ComplianceAct = () => {
  return (
    <>
      <Typography variant="h4" sx={{ color: 'gray', mb: 2 }}>
        Hello Act
      </Typography>
      <Typography variant="body1" sx={{ color: 'gray', mb: 2 }}>
        This is the Hello Act section where specific information can be displayed.
      </Typography>
    </>
  );
};

export default ComplianceAct;

import React from 'react';
import { TextField } from '@mui/material';

const SearchInput = ({ searchQuery, setSearchQuery, label }) => {
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <div style={{ flexGrow: 1, margin: '0 10px' }}>
      <TextField
        label={label} // Use the passed label
        variant="outlined"
        fullWidth
        value={searchQuery}
        onChange={handleSearchChange}
        InputProps={{
          style: {
            height: '40px',
            width: '400px'
          }
        }}
        InputLabelProps={{
          style: {
            lineHeight: '12px'
          }
        }}
      />
    </div>
  );
};

export default SearchInput;

import React from 'react';
import { Typography } from '@mui/material';
import RmfMap from './rmf-map';
import Departments from './departments';
import { useOutletContext } from 'react-router-dom';

const ComplianceRMF = () => {
  const [selectedNode, rmfData, handleDepartmentSelect, orgListArray] = useOutletContext();

  return (
    <>
      <Typography variant="h4" sx={{ color: 'gray', mb: 2 }}>
        NIST RMF
      </Typography>

      <RmfMap rmfData={rmfData} />
      <Departments onDepartmentSelect={handleDepartmentSelect} />
    </>
  );
};

export default ComplianceRMF;

import { Box, Container, Tab, Tabs, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link, Outlet, useLocation, useParams } from 'react-router-dom';
import TreeHirarchicalList from '../components/compliance/tree-hirarchical-list';
import { orgService } from '../services/org.service';

const tabs = [
  { name: 'NIST', route: 'nist' },
  { name: 'PRODUCTS', route: 'products' },
  { name: 'USERS', route: 'users' },
  { name: 'ACTIONS', route: 'actions' },
  { name: 'EU AI ACT', route: 'eu-ai-act' }
];

const ComplianceIndex = () => {
  const location = useLocation();

  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [rmfData, setRmfData] = useState(null);
  const [orgList, setOrgList] = useState({});
  const [orgListArray, setOrgListArray] = useState([]);
  const [tabIndex, setTabIndex] = useState(
    tabs.findIndex((tab) => location.pathname.indexOf('/' + tab.route) > -1)
      ? tabs.findIndex((tab) => location.pathname.indexOf('/' + tab.route) > -1)
      : 0
  );
  const { _id } = useParams();

  useEffect(() => {
    loadOrgs();
  }, []);

  const loadOrgs = async () => {
    const orgs = await orgService.queryTree(_id);
    setOrgList(orgs);
    handleNodeSelect(orgs);
  };

  const handleNodeDetails = (nodeDetails) => {
    console.log('Node and Child Details:', nodeDetails);
    setOrgListArray(nodeDetails);
  };

  const handleNodeSelect = (node) => {
    setSelectedDepartment(null);
    setSelectedNode(node);
    setRmfData(node.rmf);
  };

  const handleDepartmentSelect = (label, value) => {
    setSelectedDepartment({ label, value });

    if (rmfData) {
      const updatedRmfData = {
        manage: Math.max(1, Math.round(rmfData.manage * value)),
        measure: Math.max(1, Math.round(rmfData.measure * value)),
        map: Math.max(1, Math.round(rmfData.map * value)),
        govern: Math.max(1, Math.round(rmfData.govern * value))
      };

      setRmfData(updatedRmfData);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <Box sx={{ display: 'flex', flex: 1 }}>
      <Box sx={{ width: '20%', borderRight: '1px solid #ccc', padding: 2 }}>
        <TreeHirarchicalList
          onNodeSelect={handleNodeSelect}
          onNodeDetails={handleNodeDetails}
          parentOrg={_id}
          orgList={orgList}
          setRmfData={setRmfData}
        />
      </Box>
      <Box sx={{ width: '80%', padding: 2 }}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          aria-label="Compliance Tabs"
          sx={{ mb: 2 }}
        >
          {tabs.map((tab, index) => (
            <Tab key={tab.name} label={tab.name} component={Link} to={tab.route} />
          ))}
        </Tabs>
        <Container>
          {selectedNode && (
            <Box sx={{ mb: 3, borderBottom: '1px solid #ccc', pb: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {selectedNode.logoUrl && (
                  <img
                    src={selectedNode.logoUrl}
                    alt={`${selectedNode.name} logo`}
                    style={{
                      width: '100px',
                      height: '50px',
                      objectFit: 'contain',
                      marginRight: '12px',
                      borderRadius: '8px',
                      border: '1px solid #ccc',
                      padding: '5px'
                    }}
                  />
                )}
                <Typography variant="h5" sx={{ color: 'gray' }}>
                  {selectedNode.name} {selectedNode._id}
                </Typography>
              </Box>
            </Box>
          )}
          <Outlet context={[selectedNode, rmfData, handleDepartmentSelect, orgListArray]} />
        </Container>
      </Box>
    </Box>
  );
};

export default ComplianceIndex;

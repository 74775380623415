import { Box, Button, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import OrgCreateEdit from '../components/org/org-create-edit';
import OrgList from '../components/org/org-list';
import { orgService } from '../services/org.service';
import ConfirmationDialog from 'components/dialog/confirmation-dialog';

const OrgIndex = () => {
  const [rootOrgs, setRootOrgs] = useState([]);
  const [orgs, setOrgs] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [selectedRootOrg, setSelectedRootOrg] = useState('');

  // confirm delete dialog state
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteDialogProps, setDeleteDialogProps] = useState({
    body: '',
    onConfirm: () => null
  });

  useEffect(() => {
    loadRootOrgs();
  }, []);

  useEffect(() => {
    if (selectedRootOrg) {
      console.log('selectedRootOrg', selectedRootOrg);
      loadOrgs();
    }
  }, [selectedRootOrg]);

  const loadOrgs = async () => {
    try {
      const orgs = await orgService.query(selectedRootOrg);
      setOrgs(orgs);
      console.log('orgs', orgs);
    } catch (err) {
      console.error('Error loading orgs', err);
    }
  };

  const loadRootOrgs = async () => {
    try {
      const rootOrgs = await orgService.queryAllRoots();
      setRootOrgs(rootOrgs);
      console.log('rootOrgs', rootOrgs);
    } catch (err) {
      console.error('Error loading root orgs', err);
    }
  };

  const handleOpen = (org = null) => {
    setSelectedOrg(org);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedOrg(null);
  };

  const saveOrg = async (org) => {
    if (selectedOrg) {
      org._id = selectedOrg._id;
    }
    await orgService.save(org);
    loadOrgs();
    handleClose();
  };

  const openDeleteDialog = (org) => {
    console.log('showDeleteDialog!!', org);
    setDeleteDialogProps({
      body: `Delete organization '${org.name}' with id '${org._id}'? This action is permanent.'`,
      onConfirm: async () => {
        setShowDeleteDialog(false);
        await orgService.remove(org._id);
        loadOrgs();
      }
    });

    setShowDeleteDialog(true);
  };

  const getParentName = (parentId) => {
    const parentOrg = orgs.find((org) => org._id === parentId);
    return parentOrg ? `${parentOrg.name} - ${parentOrg._id}` : 'Root Organization';
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Organization
      </Typography>
      <FormControl fullWidth sx={{ marginBottom: 3 }}>
        <InputLabel sx={{ backgroundColor: 'white' }}>Root Organization</InputLabel>
        <Select value={selectedRootOrg} onChange={(e) => setSelectedRootOrg(e.target.value)}>
          {rootOrgs.map((org) => (
            <MenuItem key={org._id} value={org._id}>
              {org.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        sx={{ marginBottom: 3 }}
        variant="contained"
        color="primary"
        onClick={() => handleOpen()}
      >
        Add Organization
      </Button>

      <OrgCreateEdit
        selectedRootOrg={selectedRootOrg}
        open={open}
        handleClose={handleClose}
        saveOrg={saveOrg}
        org={selectedOrg}
        orgList={orgs}
      />
      <OrgList
        orgs={orgs}
        handleOpen={handleOpen}
        deleteOrg={openDeleteDialog}
        getParentName={getParentName}
      />
      <ConfirmationDialog
        open={showDeleteDialog}
        title={'Confirm Delete'}
        onCancel={() => setShowDeleteDialog(false)}
        {...deleteDialogProps}
      />
    </Box>
  );
};

export default OrgIndex;

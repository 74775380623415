import { httpService } from './http.service.js';

export const rmfActionService = {
  queryAllRmfActions,
  queryAllRmfActionsType
};
window.cs = rmfActionService;
async function queryAllRmfActions() {
  return httpService.get('rmf-actions/all');
}
async function queryAllRmfActionsType(actionType) {
  return httpService.get(`rmf-actions/type/${actionType}`);
}
// async function updateRmfAction(rmfAction) {
//   console.log("rmfAction UPDATE", rmfAction);
//   return httpService.put(`rmf-actions/${rmfAction._id}`, rmfAction);
// }

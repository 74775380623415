import { httpService } from './http.service.js';
const endpoint = 'ai_product';

export const ai_productService = {
  query,
  remove,
  save,
  getEmptyProduct

}

async function query() {
  return httpService.get(endpoint);
}

async function remove(_id) {
  return httpService.delete(`${endpoint}/${_id}`);
}

async function save(product) {

  console.log("service product front", product);
  let savedProduct;
  if (product._id) {
    console.log("update frontend service!!!!", product);
    savedProduct = await httpService.put(`ai_product/${product._id}`, product);
  } else {
    savedProduct = await httpService.post(`ai_product`, product);
  }
  return savedProduct;
}

function getEmptyProduct() {
  return {
    product_name: '',
    description: '',
    org_id: null,
    type: 'Internal',
    rmfActionCategory: "",
    rmfActions: []
  };
}
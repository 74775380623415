import {
  Box,
  Button,
  ButtonBase,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Pagination,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import SearchInput from 'components/ai-product/ai_product_search';
import { useEffect, useMemo, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { ai_productActorsService } from 'services/ai_product_actors';
import { DeleteIcon, EditIcon } from '../../../icons';
import CreateEditAiProductActor from './create-edit-ai-product-actor';

const defaultUser = () => {
  return {
    firstName: '',
    lastName: '',
    orgId: null,
    aiActors: [],
    email: ''
  };
};

const ComplianceUsers = () => {
  const [selectedNode] = useOutletContext();
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState(''); // State for search input
  const [openModal, setOpenModal] = useState(false);
  const [userToEdit, setUserToEdit] = useState(defaultUser());
  const [currentPage, setCurrentPage] = useState(1); // Pagination state
  const [itemsPerPage, setItemsPerPage] = useState(12); // Number of items per page

  useEffect(() => {
    if (selectedNode?._id) {
      getUsers(selectedNode._id);
    }
  }, [selectedNode]);

  const getUsers = async (selectedNodeId) => {
    try {
      const fetchUsers = await ai_productActorsService.getUsersWithChildren(selectedNodeId);
      setUsers(fetchUsers);
      setCurrentPage(1);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  // Filter users based on search term
  const filteredUsers = useMemo(() => {
    return users.filter(
      (user) =>
        `${user.firstName.toLowerCase()} ${user.lastName.toLowerCase()}`.includes(
          searchTerm.toLowerCase()
        ) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.orgName.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [searchTerm, users]);

  const paginatedUsers = useMemo(() => {
    return filteredUsers.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  }, [filteredUsers, currentPage, itemsPerPage]);

  const handleChangePage = (event, value) => {
    setCurrentPage(value);
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(event.target.value);
    setCurrentPage(1); // Reset to page 1 whenever items per page changes
  };

  const handleRolesChange = (event) => {
    const { value } = event.target;
    setUserToEdit((prevUser) => ({ ...prevUser, aiActors: value }));
  };

  const handleEdit = (user) => {
    setUserToEdit(user);
    setOpenModal(true);
  };

  const handleAdd = () => {
    setUserToEdit({
      ...defaultUser(),
      _id: undefined,
      orgId: selectedNode?._id
    });
    setOpenModal(true);
  };

  const handleDelete = async (userId) => {
    try {
      await ai_productActorsService.remove(userId);
      getUsers(selectedNode._id);
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    getUsers(selectedNode._id);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserToEdit((prevUser) => ({ ...prevUser, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await ai_productActorsService.save(userToEdit);
    } catch (error) {
      console.error('Error saving user:', error);
    } finally {
      handleCloseModal();
    }
  };

  return (
    <div>
      <Typography variant="h4" sx={{ color: 'gray', mb: 2 }}>
        Compliance Users
      </Typography>

      <Box display="flex" justifyContent="space-between" alignItems="flex-start" sx={{ gap: 3 }}>
        <Button
          sx={{ height: '40px', width: '200px' }}
          variant="contained"
          color="primary"
          onClick={handleAdd}
        >
          Add User
        </Button>

        {/* Use the SearchUser component here */}
        <SearchInput
          searchQuery={searchTerm}
          setSearchQuery={setSearchTerm}
          label="Search Users" // Pass a custom label
        />
        {/* Stack for items per page and pagination on the right */}
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <FormControl size="small" sx={{ minWidth: 100 }}>
            <InputLabel>Per Page</InputLabel>
            <Select value={itemsPerPage} onChange={handleItemsPerPageChange} label="Per Page">
              <MenuItem value={12}>12</MenuItem>
              <MenuItem value={24}>24</MenuItem>
              <MenuItem value={48}>48</MenuItem>
            </Select>
          </FormControl>

          <Pagination
            count={Math.ceil(filteredUsers.length / itemsPerPage)}
            page={currentPage}
            onChange={handleChangePage}
            shape="rounded"
          />
        </Box>
      </Box>

      <TableContainer component={Paper} style={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Org</TableCell>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>AI Compliance Roles</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedUsers.length > 0 ? (
              paginatedUsers.map((user) => (
                <TableRow key={user._id}>
                  <TableCell>{user.orgName}</TableCell>
                  <TableCell>{user.firstName}</TableCell>
                  <TableCell>{user.lastName}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>
                    {user.aiActors && user.aiActors.length > 0 ? (
                      user.aiActors.map((actor, index) => <div key={index}>{actor}</div>)
                    ) : (
                      <div>No AI Actors</div>
                    )}
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" spacing={2}>
                      <ButtonBase onClick={() => handleEdit(user)}>
                        <EditIcon />
                      </ButtonBase>
                      <ButtonBase onClick={() => handleDelete(user._id)}>
                        <DeleteIcon />
                      </ButtonBase>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No users found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box p={4} style={{ backgroundColor: 'white', margin: '10% auto', maxWidth: 600 }}>
          <CreateEditAiProductActor
            userToEdit={userToEdit}
            handleRolesChange={handleRolesChange}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default ComplianceUsers;

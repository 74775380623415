import { httpService } from './http.service.js';


export const traceboxService = {

  queryAlltraceboxProducts,
};
window.cs = traceboxService;
async function queryAlltraceboxProducts() {
  return httpService.get('tracebox-products');
}

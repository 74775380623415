import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';

import startCase from 'lodash/startCase';

const defaultCheckboxes = {
  nist: false,
  eu: false,
  internal_policies: false,
  uk: false,
  canada: false
};

const OrgComplianceForm = ({
  selected = [],
  states = [],
  onSelectedChange = () => null,
  onStatesChange = () => null
}) => {
  const [stateOpts, setStateOpts] = useState([]);

  useEffect(() => {
    const fetchStateOpts = async () => {
      try {
        const newStateOpts = await fetch('/assets/json/states_titlecase.json').then((response) =>
          response.json()
        );
        setStateOpts(newStateOpts);
      } catch (err) {
        console.error(err);
      }
    };

    fetchStateOpts();
  }, []);

  const checkboxOpts = Object.entries(defaultCheckboxes).map(([key, value]) => {
    return (
      <FormControlLabel
        key={key}
        control={
          <Checkbox checked={selected.indexOf(key) > -1} onChange={onSelectedChange} name={key} />
        }
        label={key === 'eu' || key === 'uk' ? key.toUpperCase() : startCase(key)}
      />
    );
  });

  return (
    <Box sx={{ display: 'flex' }}>
      <FormControl fullWidth sx={{ mt: 3 }} component="fieldset" margin="dense">
        <FormLabel component="legend">Compliance</FormLabel>
        <FormGroup row>{checkboxOpts}</FormGroup>
        <FormGroup row>
          <Autocomplete
            multiple
            options={stateOpts.map((s) => s.name)}
            freeSolo
            includeInputInList
            fullWidth
            value={states}
            onChange={(e, val) => onStatesChange(val)}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                const { key, ...tagProps } = getTagProps({ index });
                return <Chip variant="outlined" label={option} key={key} {...tagProps} />;
              })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ mt: 0 }}
                fullWidth
                variant="standard"
                label="States"
                placeholder="Type state name..."
              />
            )}
          />
        </FormGroup>
      </FormControl>
    </Box>
  );
};

export default OrgComplianceForm;

// src/components/RmfMap.js

import React from 'react';
import { Box } from '@mui/material';
import nistRmfGraph from '../../../assets/img/nist-rmf-graph.png';

const defaultStyle = {
  position: 'absolute',
  transform: 'translate(-50%, -50%)',
  color: '#225A8A',
  fontWeight: 700,
  fontSize: '1.35rem',
  fontFamily: 'Roboto',
  padding: '.25rem',
  borderRadius: '.25rem'
};

const RmfMap = ({ rmfData }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        mb: 2,
        width: '100%',
        maxWidth: '43rem',
        mx: 'auto'
      }}
    >
      <Box
        component="img"
        src={nistRmfGraph}
        alt="NIST RMF graph"
        sx={{
          width: '100%',
          height: 'auto'
        }}
      />
      {/* manage */}
      <Box
        sx={{
          ...defaultStyle,
          top: '86%',
          left: '52%'
        }}
      >
        {rmfData?.manage}
      </Box>
      {/* measure */}
      <Box
        sx={{
          ...defaultStyle,
          top: '23%',
          left: '83%',
          backgroundColor: 'rgba(255, 255, 255, 0.7)'
        }}
      >
        {rmfData?.measure}
      </Box>
      {/* map */}
      <Box
        sx={{
          ...defaultStyle,
          top: '23%',
          left: '18%',
          backgroundColor: 'rgba(255, 255, 255, 0.7)'
        }}
      >
        {rmfData?.map}
      </Box>
      {/* govern */}
      <Box
        sx={{
          ...defaultStyle,
          top: '45%',
          left: '50%',
          color: 'white'
        }}
      >
        {rmfData?.govern}
      </Box>
    </Box>
  );
};

export default RmfMap;

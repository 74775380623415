import {
  Box,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@mui/material';
import { DeleteIcon, EditIcon } from 'icons';
import { findIndex, uniqBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { ai_productActorsService } from 'services/ai_product_actors';
import { rmfActionService } from 'services/rmf-action.service';
import CheckIcon from '@mui/icons-material/Check';

const RmfActionTypes = ({
  newProduct,
  onSelectedActionChange = () => null,
  onSaveEditAction = () => null
}) => {
  const [allrmfActionsByCategory, setAllrmfActionsByCategory] = useState([]);
  const [isLoadingActions, setIsLoadingActions] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('Govern');
  const [selectedTitle, setSelectedTitle] = useState('All');
  const [searchActionQuery, setSearchActionQuery] = useState('');
  const [activeTab, setActiveTab] = useState(0);

  const [allUsers, setAllUsers] = useState([]);
  const [filteredUsersByAiActor, setFilteredUsersByAiActor] = useState([]);
  const [editingItemId, setEditingItemId] = useState(null);
  const [editedAction, setEditedAction] = useState({
    description: '',
    ai_actors: [],
    users: []
  });

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        if (newProduct?.org_id) {
          const users = await ai_productActorsService.getUsers(newProduct?.org_id);
          console.log('product front', users);
          setAllUsers(users);
        }
      } catch (err) {
        console.error('Error loading Users', err);
      }
    };

    fetchUsers();
  }, [newProduct?.org_id]);

  useEffect(() => {
    const fetchRmfActions = async () => {
      try {
        setIsLoadingActions(true);
        const allRmfActions = await rmfActionService.queryAllRmfActions();
        setAllrmfActionsByCategory(allRmfActions || []);
      } catch (err) {
        console.error('Error loading RMF Actions', err);
      } finally {
        setIsLoadingActions(false);
      }
    };

    fetchRmfActions();
  }, []);

  const filteredRmfActions = useMemo(() => {
    return allrmfActionsByCategory.filter(
      (rmfAction) =>
        ((selectedTitle === 'All' &&
          rmfAction.type.toLowerCase() === selectedCategory.toLowerCase()) ||
          rmfAction.title === selectedTitle) &&
        rmfAction.action.toLowerCase().includes(searchActionQuery.toLowerCase())
    );
  }, [selectedCategory, selectedTitle, searchActionQuery, allrmfActionsByCategory]);

  const rmfActionTitles = useMemo(() => {
    return uniqBy(
      allrmfActionsByCategory.filter(
        (o) => o.type.toLowerCase() === selectedCategory.toLowerCase()
      ),
      'title'
    );
  }, [selectedCategory, allrmfActionsByCategory]);

  const onSelectedCategoryChange = (e) => {
    setSelectedTitle('All');
    setSelectedCategory(e.target.value);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue); // Update the active tab when a new tab is clicked
  };
  const handleEditSelectedAction = (rmfAction) => {
    setEditingItemId(rmfAction.action_id);
    setEditedAction({
      description: rmfAction.description || '',
      users: rmfAction.users || [],
      ai_actors: rmfAction.ai_actors || []
    });

    const filteredUser = allUsers
      ?.filter((user) => user.aiActors.some((actor) => rmfAction.ai_actors.includes(actor)))
      .map((user) => ({
        _id: user._id,
        firstName: user.firstName,
        lastName: user.lastName
      }));
    console.log('filteredUsersByAiActor!!!!!!', filteredUser);
    setFilteredUsersByAiActor(filteredUser);
  };
  const handleSaveEditAction = async () => {
    try {
      const validUsers = editedAction.users.filter((user) => user && user._id);

      onSaveEditAction({
        action_id: editingItemId,
        users: validUsers,
        description: editedAction.description
      });

      setEditingItemId(null);
    } catch (error) {
      console.error('Error updating RMF Action:', error);
    }
  };
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginTop: 2 }}>
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        <FormControl variant="outlined" sx={{ flex: 1 }}>
          <InputLabel htmlFor="rmfActionCategory">Action Category</InputLabel>
          <Select
            id="rmfActionCategory"
            name="rmfActionCategory"
            value={selectedCategory}
            onChange={onSelectedCategoryChange}
            label="Action Category"
            variant="outlined"
            sx={{
              '& .MuiOutlinedInput-root': {
                '& .MuiSelect-outlined': {
                  padding: '10px'
                }
              }
            }}
          >
            <MenuItem value="Govern">Govern</MenuItem>
            <MenuItem value="Map">Map</MenuItem>
            <MenuItem value="Measure">Measure</MenuItem>
            <MenuItem value="Manage">Manage</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined" sx={{ flex: 1 }}>
          <InputLabel htmlFor="rmfActionsTitle">Actions Title</InputLabel>
          <Select
            id="rmfActionsTitle"
            name="rmfActionsTitle"
            value={selectedTitle}
            onChange={(e) => setSelectedTitle(e.target.value)}
            label="Actions Title"
            variant="outlined"
            sx={{
              '& .MuiOutlinedInput-root': {
                '& .MuiSelect-outlined': {
                  padding: '10px'
                }
              }
            }}
          >
            <MenuItem key="All" value="All">
              All
            </MenuItem>
            {rmfActionTitles.map((action, index) => (
              <MenuItem key={action.title} value={action.title}>
                {action.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label="Search Actions"
          variant="outlined"
          value={searchActionQuery}
          onChange={(e) => setSearchActionQuery(e.target.value)}
          sx={{ flex: 1 }}
        />
      </Box>
      {/* Tabs for Selected and Available Actions */}
      <Tabs value={activeTab} onChange={handleTabChange} aria-label="Action tabs">
        <Tab label="Selected RMF Actions" />
        <Tab label="Available RMF Actions" />
      </Tabs>
      {/* Tab Panels */}
      <Box>
        {activeTab === 0 && (
          <Box sx={{ border: '1px solid #2ea2db', borderRadius: 1, padding: 1 }}>
            <Typography variant="h6" color="#2ea2db">
              Selected RMF Actions
            </Typography>
            {isLoadingActions ? (
              <LinearProgress sx={{ my: 2 }} />
            ) : (
              <List sx={{ maxHeight: 300, overflow: 'auto', padding: 0 }}>
                {newProduct?.rmfActions?.length > 0 ? (
                  newProduct?.rmfActions?.map((rmfAction, index) => (
                    <ListItem
                      key={rmfAction.action_id}
                      disableGutters
                      divider={index < newProduct?.rmfActions?.length - 1}
                    >
                      <Box sx={{ flex: 1 }}>
                        <Typography variant="body1">{rmfAction.action}</Typography>
                        <Typography variant="body2" color="textSecondary">
                          {rmfAction.title}
                        </Typography>
                        {editingItemId === rmfAction.action_id ? (
                          <TextField
                            variant="standard"
                            size="small"
                            margin="normal"
                            label="Description"
                            fullWidth
                            value={editedAction.description}
                            onChange={(e) =>
                              setEditedAction((prev) => ({
                                ...prev,
                                description: e.target.value
                              }))
                            }
                          />
                        ) : (
                          <Typography variant="body2" color="textSecondary">
                            Description: {rmfAction.description}
                          </Typography>
                        )}

                        {editingItemId === rmfAction.action_id ? (
                          <>
                            <FormControl fullWidth variant="standard">
                              <InputLabel>Users</InputLabel>
                              <Select
                                variant="standard"
                                size="small"
                                label="Users"
                                name="Users"
                                multiple
                                value={editedAction.users.map((user) => user._id) || []} // Use user IDs as the value
                                onChange={(e) => {
                                  const { value } = e.target;

                                  const selectedUsers = value
                                    .map((userId) => {
                                      const user = allUsers.find((u) => u._id === userId);
                                      return user
                                        ? {
                                            _id: user._id,
                                            firstName: user.firstName,
                                            lastName: user.lastName
                                          }
                                        : null;
                                    })
                                    .filter(Boolean);

                                  setEditedAction((prev) => ({
                                    ...prev,
                                    users: selectedUsers
                                  }));
                                }}
                                renderValue={(selected) =>
                                  selected
                                    .map((userId) => {
                                      const user = allUsers.find((u) => u._id === userId);
                                      return user ? user.firstName + ' ' + user.lastName : '';
                                    })
                                    .join(', ')
                                }
                                MenuProps={{
                                  PaperProps: {
                                    sx: { maxHeight: 400, position: 'relative', maxWidth: 200 }
                                  }
                                }}
                              >
                                {filteredUsersByAiActor?.map((user) => (
                                  <MenuItem key={user._id} value={user._id}>
                                    <Checkbox
                                      checked={editedAction.users.some((u) => u._id === user._id)}
                                    />{' '}
                                    <ListItemText primary={user.firstName + ' ' + user.lastName} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </>
                        ) : (
                          <>
                            <Typography variant="body2" color="textSecondary">
                              Users:{' '}
                              {rmfAction.users
                                ?.map((user) => `${user.firstName} ${user.lastName}`)
                                .join(', ')}
                            </Typography>
                          </>
                        )}
                      </Box>

                      {editingItemId === rmfAction.action_id ? (
                        <IconButton aria-label="save" onClick={() => handleSaveEditAction()}>
                          <CheckIcon sx={{ color: '#2ea2db' }} />
                        </IconButton>
                      ) : (
                        <IconButton
                          aria-label="edit"
                          onClick={() => handleEditSelectedAction(rmfAction)}
                        >
                          <EditIcon sx={{ color: '#2ea2db' }} />
                        </IconButton>
                      )}

                      <IconButton
                        aria-label="remove"
                        onClick={() =>
                          onSelectedActionChange({ id: rmfAction.action_id, checked: false })
                        }
                      >
                        <DeleteIcon sx={{ color: '#2ea2db' }} />
                      </IconButton>
                    </ListItem>
                  ))
                ) : (
                  <ListItem>
                    <ListItemText primary="No selected actions" />
                  </ListItem>
                )}
              </List>
            )}
          </Box>
        )}

        {activeTab === 1 && (
          <Box sx={{ border: '1px solid #2ea2db', borderRadius: 1, padding: 1 }}>
            <Typography variant="h6" color="#2ea2db">
              Available RMF Actions
            </Typography>
            {isLoadingActions ? (
              <LinearProgress sx={{ my: 2 }} />
            ) : (
              <List sx={{ maxHeight: 300, overflow: 'auto', padding: 0 }}>
                {filteredRmfActions.length > 0 ? (
                  filteredRmfActions.map((rmfAction, index) => (
                    <ListItem
                      key={rmfAction._id}
                      disableGutters
                      divider={index < filteredRmfActions.length - 1}
                      sx={{ paddingTop: 0.5, paddingBottom: 0.5, marginTop: 0, marginBottom: 0 }}
                    >
                      <Checkbox
                        edge="start"
                        checked={
                          findIndex(newProduct.rmfActions, { action_id: rmfAction._id }) > -1
                        }
                        tabIndex={-1}
                        disableRipple
                        onChange={(e) =>
                          onSelectedActionChange({
                            id: rmfAction._id,
                            action: rmfAction.action,
                            ai_actors: rmfAction.ai_actors,
                            title: rmfAction.title,
                            checked: e.target.checked
                          })
                        }
                      />
                      <ListItemText primary={rmfAction.action} />
                    </ListItem>
                  ))
                ) : (
                  <ListItem>
                    <ListItemText primary="No actions available" />
                  </ListItem>
                )}
              </List>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default RmfActionTypes;

import { Typography } from '@mui/material';
import { useOutletContext } from 'react-router-dom';
import AiProductList from '../ai-product/ai-product-list';

const ComplianceAIProduct = () => {
  const [selectedNode, rmfData, handleDepartmentSelect, orgListArray] = useOutletContext();
  return (
    <>
      <Typography variant="h4" sx={{ color: 'gray', mb: 2 }}>
        AI Product List
      </Typography>
      <AiProductList orgListArray={orgListArray} aiProductOrgId={selectedNode?._id} />
    </>
  );
};

export default ComplianceAIProduct;

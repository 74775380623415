import Axios from 'axios';

const BASE_URL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL;

const axios = Axios.create({
    baseURL: BASE_URL,
    withCredentials: true
});

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error?.status === 401 || error?.response?.status === 401) {
            if ( window.location.pathname !== '/signin'){
                const fullPath = window.location.href.replace(window.location.origin, '');
                window.location.href = `/signin?r=${fullPath}`;
            }
        }
    }
);

export const httpService = {
    get(endpoint, data) {
        return ajax(endpoint, 'GET', data);
    },
    post(endpoint, data) {
        return ajax(endpoint, 'POST', data);
    },
    put(endpoint, data) {
        return ajax(endpoint, 'PUT', data);
    },
    delete(endpoint, data) {
        return ajax(endpoint, 'DELETE', data);
    }
};

async function ajax(endpoint, method = 'GET', data = null) {
    try {
        const res = await axios({
            url: `${endpoint}`,
            method,
            data,
            params: method === 'GET' ? data : null
        });
        return res && res.data ? res.data : res;
    } catch (err) {
        console.error(`Error ${method}ing to the backend, endpoint: ${endpoint}, with data:`, data);
        console.error('Full error:', err);
        if (err.response) {
            console.error('Response data:', err.response.data);
            console.error('Response status:', err.response.status);
            console.error('Response headers:', err.response.headers);
        } else if (err.request) {
            console.error('Request made but no response received:', err.request);
        } else {
            console.error('Error setting up request:', err.message);
        }
        throw err;
    }
}

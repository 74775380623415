import { httpService } from './http.service.js';
const endpoint = 'users';

export const ai_productActorsService = {
  getUsers,
  getUsersWithChildren,
  remove,
  save,
  getRoles
};

async function getUsers(orgId) {
  return httpService.get(`${endpoint}/orgs/${orgId}`);
}

async function getUsersWithChildren(orgId) {
  return httpService.get(`${endpoint}/orgs/${orgId}`);
}

async function remove(_id) {
  return httpService.delete(`${endpoint}/${_id}`);
}

async function save(user) {
  console.log('service product front', user);
  let savedUser;
  if (user._id) {
    console.log('update frontend service!!!!', user);
    savedUser = await httpService.put(`${endpoint}/${user._id}`, user);
  } else {
    savedUser = await httpService.post(endpoint, user);
  }
  return savedUser;
}

function getRoles() {
  return httpService.get(`ai-actors/roles`);
}

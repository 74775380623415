import { httpService } from './http.service.js';

// const STORAGE_KEY = 'org/tree';

export const rootOrgService = {
    query,
    getById,
    save,
    remove,
    getEmptyOrg,

};
window.cs = rootOrgService;

async function query() {
    return httpService.get(`root-org`);
}

function getById(rootOrgId) {
    return httpService.get(`root-org/${rootOrgId}`);
}

async function remove(rootOrgId) {
    return httpService.delete(`root-org/${rootOrgId}`);
}

async function save(org) {
    console.log('service root-org front', org);
    var savedOrg;
    if (org._id) {
        console.log('update fronted service', org);
        savedOrg = await httpService.put(`root-org/${org._id}`, org);
    } else {

        savedOrg = await httpService.post('root-org', org);
    }
    return savedOrg;
}

function getEmptyOrg() {
    return {
        name: ''
    };
}


// const items = [
//
//     {
//       id: 2,
//       name: "Intel",
//       logoUrl: "https://logos-world.net/wp-content/uploads/2021/09/Intel-Logo-2006-2020-700x394.png"
//     },
//     {
//       id: 3,
//       name: "Apple",
//       logoUrl: "https://upload.wikimedia.org/wikipedia/commons/f/fa/Apple_logo_black.svg"
//     },
//     {
//       id: 4,
//       name: "Microsoft",
//       logoUrl: "https://upload.wikimedia.org/wikipedia/commons/4/44/Microsoft_logo.svg"
//     },
//     {
//       id: 5,
//       name: "Google",
//       logoUrl: "https://upload.wikimedia.org/wikipedia/commons/2/2f/Google_2015_logo.svg"
//     },
//     {
//       id: 6,
//       name: "Amazon",
//       logoUrl: "https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg"
//     },
//     {
//       id: 7,
//       name: "Facebook",
//       logoUrl: "https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg"
//     },
//     {
//       id: 8,
//       name: "Samsung",
//       logoUrl: "https://upload.wikimedia.org/wikipedia/commons/2/24/Samsung_Logo.svg"
//     },
//     {
//       id: 9,
//       name: "Tesla",
//       logoUrl: "https://upload.wikimedia.org/wikipedia/commons/e/e8/Tesla_logo.png"
//     },
//     {
//       id: 10,
//       name: "IBM",
//       logoUrl: "https://upload.wikimedia.org/wikipedia/commons/5/51/IBM_logo.svg"
//     }
//   ];
import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton
} from '@mui/material';
import { FaEdit } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';

// TODO getParentName should be a util function that you can import
// TODO create isRootOrg in a utils js file
const OrgList = ({ orgs, handleOpen, deleteOrg, getParentName }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Parent</TableCell>
            <TableCell>Compliance</TableCell>
            <TableCell>States</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orgs.map((org) => (
            <TableRow key={org._id}>
              <TableCell>{org._id}</TableCell>
              <TableCell>{org.name}</TableCell>
              <TableCell>{org.parent_id ? getParentName(org.parent_id) : 'Root'}</TableCell>
              <TableCell>{org?.compliance?.selected?.join(', ') ?? ''}</TableCell>
              <TableCell>{org?.compliance?.states?.join(', ') ?? ''}</TableCell>
              <TableCell>
                <IconButton onClick={() => handleOpen(org)}>
                  <FaEdit />
                </IconButton>
                <IconButton onClick={() => deleteOrg(org)}>
                  <MdDelete />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OrgList;
